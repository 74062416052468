var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isDialog?'q-dialog':'div',{tag:"component",attrs:{"id":"entry-list"},on:{"hide":_vm.cancel},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('div',{class:{'dialog-view':_vm.isDialog}},[_c('ListTable',{ref:"tableRef",attrs:{"id":"entry-table","slim":"","columns":_vm.columns,"fetchAsync":_vm.fetchEntries,"dataName":"entries","selectable":_vm.selectable,"search":"orderNo,details,tags"},scopedSlots:_vm._u([{key:"add",fn:function(){return [(_vm.$can('create','Finance'))?_c('q-btn',{attrs:{"push":"","no-caps":"","color":"primary","to":{name:'finance-account-list', query:{add: 1}}}},[_c('span',{staticClass:"text-nowrap text-capitalize"},[_vm._v(_vm._s(_vm.$t('action.add')))])]):_vm._e()]},proxy:true},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("date")(item.date)))]),_c('small',{staticClass:"mt-50"},[_vm._v(_vm._s(_vm._f("time")(item.date)))])]}},{key:"cell(orderNo)",fn:function(ref){
var item = ref.item;
return [(item.orderId)?_c('q-btn',{staticClass:"w-100 rounded-lg",attrs:{"flat":"","padding":"0.25rem 0.50rem"},on:{"click":function($event){$event.stopPropagation();return _vm.openOrder({orderName: item.orderName, orderId: item.orderId})}}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-content-between align-items-center"},[(item.orderNo)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[(item.orderName==='Purchase')?_c('fa-icon',{attrs:{"icon":"shopping-cart"}}):(item.orderName==='Sale')?_c('fa-icon',{attrs:{"icon":"store"}}):_c('fa-icon',{attrs:{"icon":"dolor-sign"}})],1),_c('span',{staticClass:"mx-25"},[_vm._v(_vm._s(item.orderNo.toUpperCase()))])],1):_vm._e(),(item.details)?_c('div',{staticClass:"text-left text-wrap text-capitalize"},[_vm._v(_vm._s(item.description))]):_vm._e(),_c('FileGallery',{attrs:{"files":item.files,"subFolder":"entry","iconSize":"1x","size":"30px"}})],1),_c('fa-icon',{staticClass:"text-primary ml-1",attrs:{"icon":['far','chevron-right']}})],1):_vm._e()]}},{key:"cell(transactions)",fn:function(ref){
var item = ref.item;
return _vm._l((item.transactions),function(tran){return _c('div',{key:tran._id,staticClass:"d-flex gap-50"},[(tran.account)?_c('q-chip',{staticClass:"text-capitalize text-nowrap text-truncate my-25 flex-even",attrs:{"size":"sm","color":_vm.$t(("finance." + (tran.accountData.class) + ".color")),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("name")(tran.accountData.names))+" ")]):_vm._e(),(tran.amount && tran.account)?_c('div',{staticClass:"col text-nowrap text-right flex-even",class:tran.amount<0?'text-danger':'text-primary'},[(tran.accountData.drcr=='dr')?[_vm._v(" "+_vm._s(_vm._f("price")(tran.amount,_vm.$t(("currency." + (tran.currency) + ".sign"))))+" ")]:_vm._e()],2):_vm._e(),(tran.amount && tran.account)?_c('div',{staticClass:"col text-nowrap text-right flex-even",class:tran.amount<0?'text-danger':'text-primary'},[(tran.accountData.drcr=='cr')?[_vm._v(" "+_vm._s(_vm._f("price")(tran.amount,_vm.$t(("currency." + (tran.currency) + ".sign"))))+" ")]:_vm._e()],2):_vm._e()],1)})}},{key:"cell(tags)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column gap-1 justify-content-between"},[(item)?_c('div',{staticClass:"d-flex flex-wrap gap-1"},_vm._l((item.tags),function(tag,index){return _c('q-chip',{key:tag.index,attrs:{"color":"light-primary","dense":""}},[_vm._v(_vm._s(tag))])}),1):_vm._e()])]}},{key:"cell(createdBy)",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('div',{staticClass:"d-flex gap-1"},[_c('Avatar',{attrs:{"url":item.createdBy.files,"defaultIcon":"user"}}),_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(item.createdBy.name))]),(_vm.isMe(item.createdBy._id))?_c('q-chip',{staticClass:"m-0",attrs:{"size":"sm","color":"primary","text-color":"white"}},[_vm._v(_vm._s(_vm.$t('user.me')))]):_vm._e()],1)],1):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap d-flex gap-3 align-items-center justify-content-center"},[(_vm.$can('update','Finance'))?_c('q-btn',{attrs:{"flat":"","rounded":"","padding":"1rem"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({name: 'finance-entry-edit', params: {entryId: item._id}})}}},[_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"EditIcon","size":"16"}})],1):_vm._e()],1)]}}])}),_c('SaleView',{ref:"saleViewRef"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }