<template>
  <component :is="isDialog?'q-dialog':'div'" id="entry-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" id="entry-table" slim :columns="columns" :fetchAsync="fetchEntries" dataName="entries" :selectable="selectable" search="orderNo,details,tags">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Finance')" push no-caps color="primary" :to="{name:'finance-account-list', query:{add: 1}}">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>
        
        <!-- Date -->
        <template #cell(date)="{ item }">
          <div>{{item.date | date}}</div>
          <small class="mt-50">{{item.date | time}}</small>
        </template>
        
        <!-- Order number -->
        <template #cell(orderNo)="{ item }">
          <q-btn v-if="item.orderId" flat padding="0.25rem 0.50rem" @click.stop="openOrder({orderName: item.orderName, orderId: item.orderId})" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <q-chip v-if="item.orderNo" dense>
                <q-avatar color="primary" text-color="white">
                  <fa-icon v-if="item.orderName==='Purchase'" icon="shopping-cart" />
                  <fa-icon v-else-if="item.orderName==='Sale'" icon="store" />
                  <fa-icon v-else icon="dolor-sign" />
                </q-avatar>
                <span class="mx-25">{{item.orderNo.toUpperCase()}}</span>
              </q-chip>
              <div v-if="item.details" class="text-left text-wrap text-capitalize">{{item.description}}</div>
              <FileGallery :files="item.files" subFolder="entry" iconSize="1x" size="30px" />
            </div>
            <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
          </q-btn>
        </template>

        <!-- Transactions -->
        <template #cell(transactions)="{ item }">
          <div v-for="tran in item.transactions" :key="tran._id" class="d-flex gap-50">
            <q-chip v-if="tran.account" size="sm" :color="$t(`finance.${tran.accountData.class}.color`)" text-color="white" class="text-capitalize text-nowrap text-truncate my-25 flex-even">
              {{tran.accountData.names | name}}
            </q-chip>
            <div v-if="tran.amount && tran.account" class="col text-nowrap text-right flex-even" :class="tran.amount<0?'text-danger':'text-primary'">
              <template v-if="tran.accountData.drcr=='dr'">
                {{tran.amount | price($t(`currency.${tran.currency}.sign`))}}
              </template>
            </div>
            <div v-if="tran.amount && tran.account" class="col text-nowrap text-right flex-even" :class="tran.amount<0?'text-danger':'text-primary'">
              <template v-if="tran.accountData.drcr=='cr'">
                {{tran.amount | price($t(`currency.${tran.currency}.sign`))}}
              </template>
            </div>
          </div>
        </template>

        <!-- Tags -->
        <template #cell(tags)="{ item }">
          <div class="d-flex flex-column gap-1 justify-content-between">
            <div v-if="item" class="d-flex flex-wrap gap-1">
              <q-chip v-for="(tag, index) in item.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
            </div>
          </div>
        </template>

        <!-- Created By -->
        <template #cell(createdBy)="{ item }">
          <div v-if="item.createdBy" class="d-flex gap-1">
            <Avatar :url="item.createdBy.files" defaultIcon="user"/>
            <div class="text-left">
              <div>{{ item.createdBy.name }}</div>
              <q-chip v-if="isMe(item.createdBy._id)" size="sm" color="primary" text-color="white" class="m-0">{{$t('user.me')}}</q-chip>
            </div>
          </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap d-flex gap-3 align-items-center justify-content-center">
            <q-btn flat rounded padding="1rem" v-if="$can('update','Finance')" @click.stop="$router.push({name: 'finance-entry-edit', params: {entryId: item._id}})">
              <feather-icon  icon="EditIcon" class="text-primary cursor-pointer" size="16"/>
            </q-btn>
          </div>
        </template>
      </ListTable>
      <SaleView ref="saleViewRef" />
    </div>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { date, time, price, mmdd, capitalize, i18n, name, isMe } from '@core/utils/filter'
import store from '@/store'
import ListTable from '@core/components/ListTable'
import FileGallery from '@core/components/file-gallery/FileGallery'
import FieldDialog from '@core/components/FieldDialog'
import Avatar from '@core/components/Avatar'

import SaleView from '@/views/supply-chain/sale/SaleView'
import PurchaseView from '@/views/supply-chain/purchase/PurchaseView'

export default {
  name: 'entry-list',
  components: {
    ListTable,
    FileGallery,
    Avatar, FieldDialog,
    SaleView, PurchaseView
  },
  props:{
    isDialog: Boolean,
    hideTabs: Boolean,
    showIcon: Boolean,
    selectable: {
      type:Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters:{
    date, price, time, mmdd, capitalize, name
  },
  setup() {
    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'entrySeq', label: '#', noFilter: true, sortable: true},
      { key: 'date', label:i18n('form.date'), isDateRange: true, sortable: true },
      { key: 'orderNo', label:i18n('form.order_no'), sortable: true },
      { key: 'transactions', label:i18n('finance.transaction.transactions'), noFilter: true, class:"w-33"},
      { key: 'tags', label:i18n('form.tags'), sortable: true},
      { key: 'createdBy', label:i18n('form.created_by'), sortable: true, noFilter: true},
      { key: 'actions', noFilter: true, class:"w-10" },
    ])

    // Action
    const fetchEntries = params => store.dispatch('entry/fetchEntries', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    const openOrder = ({orderName, orderId})=>{
      if (orderName === 'Sale') saleViewRef.value.open(orderId)//.then(v=>v?tableRef.refetchTable():'')
      if (orderName === 'Purchase') ourchaseViewRef.value.open(orderId)//.then(v=>v?tableRef.refetchTable():'')
    }

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async obj =>{
      const newTags = await fieldDialogRef.value.openDialog({data:obj.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      store.dispatch('entry/updateTags', {entryId: obj._id, tags:newTags}).then(r=>{
        obj.tags = r.tags
      }).catch(e=> Dialog(false).fire({
        icon: 'error',
        title: e.message
      }))
    }

    // Refs
    const saleViewRef = ref(null)
    const purchaseViewRef = ref(null)
  
    return {
      isMe,
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchEntries,
      updateTags,
      openOrder,

      // Refs
      fieldDialogRef,
      saleViewRef,
      purchaseViewRef
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filter-table-selector{
  min-width: 90px
}
</style>

<style lang="scss">
#entry-list{
  .w-10 {width: 10%;}
  .w-33 {width: 33%;}
  td:first-child {
    max-width: 80px;
  }
}

</style>
